import team_1 from '../assets/team_1.jpg';
import team_2 from '../assets/team_2.jpg';
import team_3 from '../assets/team_3.jpg';


export const teamMembersData = [
    {
        image : team_1,
        name : "Full Name",
        des : "Designation"
    },
    {     
        image : team_2,
        name : "Full Name",
        des : "Designation"
    },    
    {
        image : team_3,
        name : "Full Name",
        des : "Designation"
    },

];