import React from 'react';
import './Project.css';

const Project = () => {
  return (
    <div className='project'>
        <div className="project-sec">
            <h1>Do You Have Any Project?</h1>
            <p>Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat dolor rebum sit ipsum.</p>
            <a href="#">Contact Us</a>
        </div>
    </div>
  )
}

export default Project